import React from "react";
import { Link, Outlet, useMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { cls } from "@/libs/helper";
import { LIST_TYPE, packageListType } from "@/recoil/atoms";
import Parser from "html-react-parser";
import { getCountry } from "@/api/contents";
import CommonHelmet from "@/components/common/CommonHelmet";

const PackageList = () => {
  const isPackage = useMatch("/:country/package");
  const isPackageDetail = useMatch("/:country/package/:id");
  const country = isPackage?.params?.country || "philippines";

  const isID = isPackageDetail?.params?.id ? true : false;
  const rawData = getCountry()[country];
  const packageData = rawData?.package;
  const countryName = rawData?.title;
  const selectedListType = useRecoilValue(packageListType);
  const countryPath = country ? `${country}/package` : "package";
  const imgPrefix = `../../../assets/images/${countryPath}/`;
  if (isID) return <Outlet />;
  else
    return (
      <>
        <CommonHelmet text={`${countryName} 골프 패키지 :: 골프고고`} />
        <dl
          className={cls(
            "packageList",
            selectedListType == LIST_TYPE.list ? "listType" : "blockType",
          )}
        >
          {packageData?.map((item, index) => (
            <dd key={index}>
              <Link to={`${item.id}`}>
                <div className="thum">
                  <img src={`${imgPrefix}${item.img}`} />
                </div>
                <div className="txA">
                  <div className="subject">
                    <span>{Parser(item.title)}</span> {Parser(item.subTitle)}
                  </div>
                  <div className="price">
                    {item.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    <span className="won">원 ~</span>{" "}
                    <span className="pr">{item.perPrice}</span>
                  </div>
                  <div className="ctSubs">{Parser(item.summary)}</div>
                </div>
              </Link>
            </dd>
          ))}
        </dl>
      </>
    );
};
export default PackageList;
