import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import router from "route/router";
import Footer from "components/common/footer";
import TopNavigation from "components/common/topNavigation";
import KakaoFloatingBtn from "components/common/KakaoFloatingBtn";
import Popup from "components/popup";
import ScrollToTop from "api/scrollToTop";
import "assets/css/reset.css";
import "assets/css/include.css";
import "assets/css/mo_include.css";
import "assets/css/main.css";
import "assets/css/mo_main.css";
import "assets/css/sub.css";
import "assets/css/mo_sub.css";
import "assets/css/slick.css";
import "assets/css/slick-theme.css";

const Routing = () => {
  let routes = useRoutes(router);
  return routes;
};
//tst
const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <TopNavigation />
      <Routing />
      <KakaoFloatingBtn />
      <Footer />
      {/* <Popup /> */}
    </Router>
  );
};

// stage clean test

export default App;
