import React from "react";
import Slick from "react-slick";
import { Link } from "react-router-dom";
import { mainJs } from "@/assets/js/common";

const MainPromotionSlider = () => {
  return (
    <div className="promotion">
      <div className="mainTitle">한정특가 프로모션</div>
      <dl className="promotionList">
        <Slick {...mainJs.settingPromotionList}>
          <dd>
            <div className="textArea">
              <div className="subs">필리핀 3박 5일 매일 18홀 선택</div>
              <div className="subj">풀빌라 패키지 54홀</div>
              <Link to="/philippines/package/0">
                <div className="more">일정 안내</div>
              </Link>
            </div>
            <div className="thum">
              <img src="assets/images/promotionThum01.jpg" alt="" />
            </div>
          </dd>

          <dd>
            <div className="textArea">
              <div className="subs">필리핀에서 즐기는 골프여행</div>
              <div className="subj">필리핀 게스트하우스 3일 특가상품</div>
              <div className="moreList">
                <li>
                  <Link to="/booking">예약문의</Link>
                </li>
                <li>
                  <Link to="/philippines/enjoy">필리핀 즐기기</Link>
                </li>
                <li>
                  <Link to="/philippines/package/1">일정안내</Link>
                </li>
              </div>
            </div>
            <div className="thum">
              <img src="assets/images/promotionThum02.jpg" alt="" />
            </div>
          </dd>

          <dd>
            <div className="textArea">
              <div className="subs">필리핀 54홀 골프여행</div>
              <div className="subj">호텔 3박 5일 + 매일 18홀 선택 C.C</div>
              <div className="more">
                <Link to="/philippines/package/2">일정 안내</Link>
              </div>
            </div>
            <div className="thum">
              <img src="assets/images/promotionThum03.jpg" alt="" />
            </div>
          </dd>
        </Slick>
      </dl>
    </div>
  );
};

export default MainPromotionSlider;
