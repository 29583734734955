export const apiUrls = {
  login: "user/userLogin",
  signup: "user/userSignUp",
  reqId: "user/userReqId",
  reqPw: "user/userReqPw",
  updatePw: "user/userUpdatePw",

  getBusinessList: "board/getBusinessList",
  getBusinessDetail: (postId = 1) => `board/${postId}/businessDetail`,
  deleteBusiness: "board/deleteBusiness",
  writeBusiness: "board/writeBusiness",
  updateBusiness: "board/updateBusiness",
  saveWriteImg: "board/saveWriteImg",


  // join: "user/join",
  // tokenCheck: "auth/tokenCheck",
  // sendEmail: "help/sendEmail",
  // getReferenceList: "board/getReferenceList",
  // getReferenceListMain: "board/getReferenceListMain",
  // getReferenceDetail: (postId = 1) => `board/${postId}/referenceDetail`,
  // deleteReference: "board/deleteReference",
  // writeReference: "board/writeReference",
  // updateReference: "board/updateReference",
  // getTrendList: "board/getTrendList",
  // getTrendDetail: (postId = 1) => `board/${postId}/trendDetail`,
  // deleteTrend: "board/deleteTrend",
  // writeTrend: "board/writeTrend",
  // updateTrend: "board/updateTrend",
  // checkPw: "board/checkPw",
  // getHistory: "history/getHistory",
  // saveWriteImg: "board/saveWriteImg",
};
