import React from "react";
import Slick from "react-slick";
import { mainJs } from "@/assets/js/common";

const MainVisualSlider = () => {
  return (
    <div className="visual">
      <Slick {...mainJs.settingMainVisual} className="mainVisual">
        <li>
          <div className="cont">
            <div className="title">
              골퍼들이 원하는 <br />
              진정한 골프 투어
            </div>
            <div className="substance">
              Design
              <br />
              The Value of Time <span>골프고고</span>
            </div>
          </div>
          <div className="bg">
            <img src="assets/images/main/mainVisual01.jpg" alt="" />
          </div>
        </li>

        <li>
          <div className="cont">
            <div className="title">
              골퍼들이 원하는 <br />
              맞춤 서비스
            </div>
            <div className="substance">
              골프 고고에서 경험 하세요 <span>골프고고</span>
            </div>
          </div>
          <div className="bg">
            <img src="assets/images/main/mainVisual02.jpg" alt="" />
          </div>
        </li>

        <li>
          <div className="cont">
            <div className="title">
              골퍼들의 니즈를 <br />
              충족하는 서비스
            </div>
            <div className="substance">편안하고 행복한 골프여행</div>
          </div>
          <div className="bg">
            <img src="assets/images/main/mainVisual03.jpg" alt="" />
          </div>
        </li>
      </Slick>
    </div>
  );
};

export default MainVisualSlider;
