import React from "react";
import Home from "pages/home";
import About from "pages/about";
import Notice from "pages/notice";
import NoticeDetail from "pages/notice-detail";
import ErrorPage from "pages/errorPage";
import Booking from "pages/booking";
import Country from "pages/packages/country";
import PackageDetail from "pages/packages/common/package-detail";
import Enter from "pages/admin/enter";
import WritePage from "pages/admin/write";
import AdminInfo from "pages/admin/admin-info";
import RequireAuth from "components/auth/require-auth";
import GolfList from "pages/packages/common/golf";
import HotelList from "pages/packages/common/hotel";
import EnjoyList from "pages/packages/common/enjoy";
import PackageList from "pages/packages/common/package";
import BusinessPage from "pages/main/businessPage";
import BusinessDetail from "pages/main/business/businessDetail";
import BusinessEdit from "pages/main/business/businessEdit";
import BusinessWrite from "pages/main/business/businessWrite";

const router = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  {
    path: "philippines/*",
    element: <Country />,
    children: [
      {
        path: `package/*`,
        element: <PackageList />,
        children: [
          {
            path: `:id`,
            element: <PackageDetail />,
          },
        ],
      },
      {
        path: `golf`,
        element: <GolfList />,
      },
      {
        path: `hotel`,
        element: <HotelList />,
      },
      {
        path: `enjoy`,
        element: <EnjoyList />,
      },
    ],
  },
  {
    path: "japan/*",
    element: <Country />,
    children: [
      {
        path: `package/*`,
        element: <PackageList />,
        children: [
          {
            path: `:id`,
            element: <PackageDetail />,
          },
        ],
      },
      {
        path: `golf`,
        element: <GolfList />,
      },
      {
        path: `hotel`,
        element: <HotelList />,
      },
      {
        path: `enjoy`,
        element: <EnjoyList />,
      },
    ],
  },
  {
    path: "thailand/*",
    element: <Country />,
    children: [
      {
        path: `package/*`,
        element: <PackageList />,
        children: [
          {
            path: `:id`,
            element: <PackageDetail />,
          },
        ],
      },
      {
        path: `golf`,
        element: <GolfList />,
      },
      {
        path: `hotel`,
        element: <HotelList />,
      },
      {
        path: `enjoy`,
        element: <EnjoyList />,
      },
    ],
  },
  {
    path: "uae/*",
    element: <Country />,
    children: [
      {
        path: `package/*`,
        element: <PackageList />,
        children: [
          {
            path: `:id`,
            element: <PackageDetail />,
          },
        ],
      },
      {
        path: `golf`,
        element: <GolfList />,
      },
      {
        path: `hotel`,
        element: <HotelList />,
      },
      {
        path: `enjoy`,
        element: <EnjoyList />,
      },
    ],
  },
  {
    path: "notice/*",
    element: <Notice />,
    children: [
      {
        path: `:id`,
        element: <NoticeDetail />,
      },
    ],
  },
  { path: "/booking", element: <Booking /> },
  { path: "/admin", element: <Enter /> },
  {
    path: "/admin-info",
    element: (
      <RequireAuth>
        <AdminInfo />
      </RequireAuth>
    ),
  },
  {
    path: "/write",
    element: (
      <RequireAuth>
        <WritePage />
      </RequireAuth>
    ),
  },
  {
    path: "/gallery",
    element: <BusinessPage />,
    children: [
      {
        path: `write`,
        element: <BusinessWrite />,
      },
      {
        path: `edit/:postId`,
        element: <BusinessEdit />,
      },
      {
        path: `detail/:postId`,
        element: <BusinessDetail />,
      },
    ],
  },

  { path: "*", element: <ErrorPage /> },
];

export default router;
