import React from "react";

const WritePage = () => {
  return (
    <>
      <h1>Notice 글쓰기</h1>
      <div>
        <form>
          <input type="text" placeholder="ID" />
          <input type="password" placeholder="PW" />
        </form>
      </div>
    </>
  );
};

export default WritePage;
