import React from "react";
import $ from "jquery";

const allMenuMouseclick = function () {
  $(".allDropMenu").show();
  $(".dropMenu01").css("display", "none");
  $(".dropMenu02").css("display", "none");
  $(".dropMenu03").css("display", "none");
  $(".dropMenu04").css("display", "none");
  $(".dropMenu05").css("display", "none");
};

const allDropMenuMouseLeave = function () {
  $(".allDropMenu").hide();
};

const menu01MouseOver = function () {
  $(".dropMenu01").show();
  $(".allDropMenu").css("display", "none");
  $(".dropMenu05").css("display", "none");
  $(".dropMenu04").css("display", "none");
  $(".dropMenu03").css("display", "none");
  $(".dropMenu02").css("display", "none");
};

const dropMenu01Mouseleave = function () {
  $(".dropMenu01").hide();
};

const menu02Mouseover = function () {
  $(".dropMenu02").show();
  $(".allDropMenu").css("display", "none");
  $(".dropMenu05").css("display", "none");
  $(".dropMenu04").css("display", "none");
  $(".dropMenu03").css("display", "none");
  $(".dropMenu01").css("display", "none");
};

const dropMenu02Mouseleave = function () {
  $(".dropMenu02").hide();
};

const menu03Mouseover = function () {
  $(".dropMenu03").show();
  $(".allDropMenu").css("display", "none");
  $(".dropMenu05").css("display", "none");
  $(".dropMenu04").css("display", "none");
  $(".dropMenu02").css("display", "none");
  $(".dropMenu01").css("display", "none");
};

const dropMenu03Mouseleave = function () {
  $(".dropMenu03").hide();
};

const menu04Mouseover = function () {
  $(".dropMenu04").show();
  $(".allDropMenu").css("display", "none");
  $(".dropMenu05").css("display", "none");
  $(".dropMenu03").css("display", "none");
  $(".dropMenu02").css("display", "none");
  $(".dropMenu01").css("display", "none");
};

const dropMenu04Mouseleave = function () {
  $(".dropMenu04").hide();
};

const menu05Mouseover = function () {
  $(".dropMenu05").show();
  $(".allDropMenu").css("display", "none");
  $(".dropMenu04").css("display", "none");
  $(".dropMenu02").css("display", "none");
  $(".dropMenu03").css("display", "none");
  $(".dropMenu01").css("display", "none");
};

const dropMenu05Mouseleave = function () {
  $(".dropMenu05").hide();
};

const menu05menu06Mouseover = function () {
  $(".dropMenu").hide();
};

const headerMouseover = function () {
  $("#header").css({
    background: "rgba(255,255,255,1)",
    transition: ".2s background ease-in-out",
  });
  $("#header .logo .logo_w").css("display", "none");
  $("#header .logo .logo_c").css("display", "block");
  $("#header .menu li a").css("color", "#222");
  $("#header .menu_c").css("display", "block");
  $("#header .menu_w").css("display", "none");
};

const headerMouseleave = function (title) {
  if (title == "home") {
    $("#header").css({
      background: "rgba(255,255,255,0)",
      transition: ".2s background ease-in-out",
    });
    $("#header .logo .logo_w").css("display", "block");
    $("#header .logo .logo_c").css("display", "none");
    $("#header .menu li a").css("color", "#fff");
    $("#header .menu_c").css("display", "none");
    $("#header .menu_w").css("display", "block");
    $("#header .dropMenu").hide();
  } else {
    $(".subWrap #header").mouseleave(function () {
      $("#header").css("background", "rgba(255,255,255,0)");
      $("#header .logo .logo_w").css("display", "none");
      $("#header .logo .logo_c").css("display", "block");
      $("#header .menu li a").css("color", "#222");
      $("#header .menu_c").css("display", "block");
      $("#header .menu_w").css("display", "none");
      $("#header .dropMenu").hide();
    });
  }
};

const mm = function (no) {
  $(".mm_" + no).toggleClass("toggleClass");
  $(".mm_D" + no).toggle();

  menuCloseClick();
};

const mm_D2 = function (no) {
  $(".mm_D2_" + no).toggleClass("toggleClass");
  $(".mm_D2_D" + no).toggle();

  menuCloseClick();
};

const mm_D3 = function (no) {
  $(".mm_D3_" + no).toggleClass("toggleClass");
  $(".mm_D3_D" + no).toggle();

  menuCloseClick();
};

const mm_D4 = function (no) {
  $(".mm_D4_" + no).toggleClass("toggleClass");
  $(".mm_D4_D" + no).toggle();

  menuCloseClick();
};

const mm_D5 = function (no) {
  $(".mm_D5_" + no).toggleClass("toggleClass");
  $(".mm_D5_D" + no).toggle();

  menuCloseClick();
};

const moMenuClick = function () {
  $(".moSlideMenu").css("right", "0%");
};

const menuCloseClick = function () {
  $(".moSlideMenu").css("right", "-100%");
};

const mainJs = {
  golfChg: (no) => {
    let titleElm;
    let viewElm;
    const _maxLen = document.querySelector(`.golfTab`).children.length;
    for (let i = 0; i < _maxLen; i++) {
      titleElm = document.querySelector(`.golfTab .golfTitle_${i + 1}`);
      viewElm = document.querySelector(`.golfView_${i + 1}`);
      if (no == i + 1) {
        titleElm.classList.add("on");
        if (viewElm) viewElm.classList.add("on");
      } else {
        titleElm.classList.remove("on");
        if (viewElm) viewElm.classList.remove("on");
      }
    }
  },
  settingMainVisual: {
    autoplay: true,
    arrows: true,
    dots: false,
    infinite: true,
  },
  settingGolfVisual: {
    autoplay: true,
    arrows: false,
    dots: true,
    infinite: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "220px",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          arrows: false,
          slidesToShow: 3,
          centerPadding: "10px",
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  },
  settingPromotionList: {
    autoplay: false,
    arrows: false,
    dot: false,
    centerMode: true,
    centerPadding: "390px",
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "240px",
          centerMode: true,
        },
      },
      {
        breakpoint: 840,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  },
  pvVisualSetting: {
    autoplay: false,
    arrows: true,
    dots: false,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  },
};

const subJs = {
  settingDocList: {
    autoplay: false,
    arrows: true,
    dots: false,
    centerMode: true,
    centerPadding: "240px",
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "240px",
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  },
  settingGolfLoVisual: {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    customPaging: (i) => (
      <a>
        <div className="dot">.</div>
      </a>
    ),
    dotsClass: "slick-dots slick-thumb",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  },
  settingEnjoyVisual: {
    // customPaging: (i) => {
    //   console.log(accumulateCount, "@!!#!@#");
    //   arrangeCount = count;
    //   if (i == 0) count++;
    //   accumulateCount = Math.floor(arrangeCount / 2);
    //   data = enjoyData[accumulateCount]?.images[i]?.replace('"', "");
    //   return (
    //     <a>
    //       <img src={`${imgPrefix}${data}`} />
    //     </a>
    //   );
    // },

    //dotsClass: "slick-dots slick-thumb",
    //dots: true,
    //dotsClass: "slick-dots slick-thumb",
    // dots: false,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,

    // centerMode: true,
    // focusOnSelect: true,
    // arrows: false,
    //fade: true

    autoplay: false,
    arrows: false,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: "390px",
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "240px",
          centerMode: true,
        },
      },
      {
        breakpoint: 840,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  },
};

export {
  mainJs,
  subJs,
  allMenuMouseclick,
  allDropMenuMouseLeave,
  menu01MouseOver,
  dropMenu01Mouseleave,
  menu02Mouseover,
  dropMenu02Mouseleave,
  menu03Mouseover,
  dropMenu03Mouseleave,
  menu04Mouseover,
  dropMenu04Mouseleave,
  menu05Mouseover,
  dropMenu05Mouseleave,
  menu05menu06Mouseover,
  headerMouseover,
  menuCloseClick,
  moMenuClick,
  headerMouseleave,
  mm,
  mm_D2,
  mm_D3,
  mm_D4,
  mm_D5,
};
