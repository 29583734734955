import React from "react";
import { Link } from "react-router-dom";

const QuickMenu = () => {
  return (
    <ul className="quickMenu">
      <li>
        <Link to="/notice">
          <div className="ico">
            <img src="../assets/images/sub/quickIco01.png" />
          </div>
          <div className="text">공지사항</div>
        </Link>
      </li>

      <li>
        <Link to="/booking">
          <div className="ico">
            <img src="../assets/images/sub/quickIco02.png" />
          </div>
          <div className="text">예약문의</div>
        </Link>
      </li>

      <li>
        <Link to="/gallery">
          <div className="ico">
            <img src="../../assets/images/sub/quickIco03.png" />
          </div>
          <div className="text">골프갤러리</div>
        </Link>
      </li>
    </ul>
  );
};

export default QuickMenu;
