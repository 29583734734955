import { atom, selector } from "recoil";

const cityAtom = atom({
  key: "setCity",
  default: 0,
});

const LIST_TYPE = {
  list: "LIST",
  thumb: "THUMB",
};

const packageListType = atom({
  key: "setListType",
  default: LIST_TYPE.list,
});

const countryAtom = atom({
  key: "setCountry",
  default: 0,
});

const countryNumMap = ["philippines", "japan", "thailand", "uae"];

const channelIDMap = {
  home: "_xnxjFcxj/chat",
  philippines: "_xnxjFcxj/chat",
  japan: "_xnxjFcxj/chat",
  thailand: "_qAMYb/chat",
  uae: "_NXWxgb/chat",
};

const kakaoMapSelector = selector({
  key: "kakaoMapSelector",
  get: ({ get }) => {
    const num = get(countryAtom);
    return countryNumMap[num];
  },
});

export {
  LIST_TYPE,
  cityAtom,
  packageListType,
  channelIDMap,
  countryAtom,
  kakaoMapSelector,
};
