import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import * as topMenu from "@/assets/js/common";
import { CustomLink } from "@/libs/helper";
import CommonHelmet from "components/common/CommonHelmet";
import { useLocation } from "react-router-dom";

const TopNavigation = () => {
  const location = useLocation();
  const title = location.pathname === "/" ? "home" : "";

  useEffect(() => {
    topMenu.headerMouseover();
  });

  return (
    <>
      <CommonHelmet text={title ? title : "골프여행은 골프고고 :: golfgogo"} />
      <header
        id="header"
        onMouseOver={topMenu.headerMouseover}
        onMouseLeave={() => topMenu.headerMouseleave(title)}
      >
        <div className="logo">
          <Link to="/">
            <img src={"/assets/images/logo_w.png"} className="logo_w" />
            <img src="/assets/images/logo_c.png" className="logo_c" />
          </Link>
        </div>

        <ul className="menu">
          <li className="menu01" onMouseOver={topMenu.menu01MouseOver}>
            <CustomLink to="/about" menu="about">
              회사소개
            </CustomLink>
          </li>
          <li className="menu02" onMouseOver={topMenu.menu02Mouseover}>
            <CustomLink to="/philippines/package" menu="philippines">
              필리핀
            </CustomLink>
          </li>
          <li className="menu03" onMouseOver={topMenu.menu03Mouseover}>
            <CustomLink to="/japan/package" menu="japan">
              일본
            </CustomLink>
          </li>
          <li className="menu04" onMouseOver={topMenu.menu04Mouseover}>
            <CustomLink to="/thailand/package" menu="thailand">
              태국
            </CustomLink>
          </li>
          <li className="menu05" onMouseOver={topMenu.menu05Mouseover}>
            <CustomLink to="/uae/package" menu="uae">
              두바이
            </CustomLink>
          </li>
          <li className="menu06" onMouseOver={topMenu.menu05menu06Mouseover}>
            <CustomLink to="/notice" menu="notice">
              공지사항
            </CustomLink>
          </li>
          <li className="menu06" onMouseOver={topMenu.menu05menu06Mouseover}>
            <CustomLink to="/booking" menu="booking">
              예약문의
            </CustomLink>
          </li>
          <li className="menu06" onMouseOver={topMenu.menu05menu06Mouseover}>
            <CustomLink to="/gallery" menu="gallery">
              골프갤러리
            </CustomLink>
          </li>
        </ul>

        <div className="rtMenu">
          <div className="allMenu" onClick={topMenu.allMenuMouseclick}>
            <img src="/assets/images/allMenu.png" className="menu_w" />
            <img src="/assets/images/allMenu_c.png" className="menu_c" />
          </div>

          <div className="moMenu" onClick={topMenu.moMenuClick}>
            <img src="/assets/images/allMenu.png" className="menu_w" />
            <img src="/assets/images/allMenu_c.png" className="menu_c" />
          </div>
        </div>

        <div
          className="dropMenu allDropMenu"
          onClick={topMenu.allMenuMouseclick}
          onMouseLeave={topMenu.allDropMenuMouseLeave}
        >
          <div className="titArea">
            <div className="tit">전체메뉴</div>
            <div className="subTit">Golfgogo Sitemap</div>
          </div>
          <div className="menuCont">
            <dl className="menuList allDrop-w24">
              <dd>
                <div className="depth01Box">
                  <CustomLink to="/about" menu="about">
                    회사소개
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>

              <dd>
                <div className="depth01Box">
                  <CustomLink to="/philippines/package" menu="philippines">
                    필리핀
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>

              <dd>
                <div className="depth01Box">
                  <CustomLink to="/japan/package" menu="japan">
                    일본
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>

              <dd>
                <div className="depth01Box">
                  <CustomLink to="/thailand/package" menu="thailand">
                    태국
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>

              <dd>
                <div className="depth01Box">
                  <CustomLink to="/uae/package" menu="uae">
                    두바이
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>

              <dd>
                <div className="depth01Box">
                  <CustomLink to="/notice" menu="notice">
                    공지사항
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>

              <dd>
                <div className="depth01Box">
                  <CustomLink to="/booking" menu="booking">
                    예약문의
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>

              <dd>
                <div className="depth01Box">
                  <CustomLink to="/gallery" menu="gallery">
                    골프갤러리
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>
            </dl>
          </div>
          <div className="dropmenuBG">
            <img src="/assets/images/dropmenuIMG02.jpg" />
          </div>
        </div>

        <div
          className="dropMenu dropMenu01"
          onMouseLeave={topMenu.dropMenu01Mouseleave}
        >
          <div className="titArea">
            <div className="tit">회사소개</div>
            <div className="subTit">About Us</div>
          </div>
          <div className="menuCont">
            <dl className="menuList">
              <dd>
                <div className="depth01Box">
                  <CustomLink to="/about" menu="about">
                    인사말
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
              </dd>
            </dl>
          </div>
          <div className="dropmenuBG">
            <img src="/assets/images/dropmenuIMG02.jpg" />
          </div>
        </div>

        <div
          className="dropMenu dropMenu02"
          onMouseLeave={topMenu.dropMenu02Mouseleave}
        >
          <div className="titArea">
            <div className="tit">필리핀</div>
            <div className="subTit">Philippines</div>
          </div>
          <div className="menuCont">
            <dl className="menuList">
              <dd>
                <div className="depth01Box">
                  <CustomLink to="/philippines/package" menu="philippines">
                    필리핀
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
                <ul className="depth02List">
                  <li>
                    <CustomLink to="/philippines/package">
                      골프 패키지
                    </CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/philippines/golf">골프장 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/philippines/hotel">호텔 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/philippines/enjoy">
                      필리핀 즐기기
                    </CustomLink>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="dropmenuBG">
            <img src="/assets/images/dropmenuIMG02.jpg" />
          </div>
        </div>

        <div
          className="dropMenu dropMenu03"
          onMouseLeave={topMenu.dropMenu03Mouseleave}
        >
          <div className="titArea">
            <div className="tit">일본</div>
            <div className="subTit">Japan</div>
          </div>
          <div className="menuCont">
            <dl className="menuList">
              <dd>
                <div className="depth01Box">
                  <CustomLink to="/japan/package" menu="japan">
                    일본
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
                <ul className="depth02List">
                  <li>
                    <CustomLink to="/japan/package">골프 패키지</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/japan/golf">골프장 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/japan/hotel">호텔 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/japan/enjoy">일본 즐기기</CustomLink>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="dropmenuBG">
            <img src="/assets/images/dropmenuIMG02.jpg" />
          </div>
        </div>

        <div
          className="dropMenu dropMenu04"
          onMouseLeave={topMenu.dropMenu04Mouseleave}
        >
          <div className="titArea">
            <div className="tit">태국</div>
            <div className="subTit">Thailand</div>
          </div>
          <div className="menuCont">
            <dl className="menuList">
              <dd>
                <div className="depth01Box">
                  <CustomLink to="/thailand/package" menu="thailand">
                    태국
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
                <ul className="depth02List">
                  <li>
                    <CustomLink to="/thailand/package">골프 패키지</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/thailand/golf">골프장 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/thailand/hotel">호텔 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/thailand/enjoy">태국 즐기기</CustomLink>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="dropmenuBG">
            <img src="/assets/images/dropmenuIMG02.jpg" />
          </div>
        </div>
        <div
          className="dropMenu dropMenu05"
          onMouseLeave={topMenu.dropMenu05Mouseleave}
        >
          <div className="titArea">
            <div className="tit">두바이</div>
            <div className="subTit" style={{ wordBreak: "keep-all" }}>
              United Arab Emirates
            </div>
          </div>
          <div className="menuCont">
            <dl className="menuList">
              <dd>
                <div className="depth01Box">
                  <CustomLink to="/uae/package" menu="uae">
                    두바이
                    <span>
                      <img src="/assets/images/menu_l_arrow.png" />
                    </span>
                  </CustomLink>
                </div>
                <ul className="depth02List">
                  <li>
                    <CustomLink to="/uae/package">골프 패키지</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/uae/golf">골프장 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/uae/hotel">호텔 정보</CustomLink>
                  </li>
                  <li>
                    <CustomLink to="/uae/enjoy">두바이 즐기기</CustomLink>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
          <div className="dropmenuBG">
            <img src="/assets/images/dropmenuIMG02.jpg" />
          </div>
        </div>

        <div className="moSlideMenu">
          <div className="topLine">
            <div className="logo">
              <Link to="/">
                <img src="/assets/images/logo_c.png" />
              </Link>
            </div>
            <div className="menuClose" onClick={topMenu.menuCloseClick}>
              <img src="/assets/images/close_w.png" />
            </div>
          </div>

          <dl className="menuList">
            <dd className="pm">
              <CustomLink
                to="/about"
                onClick={() => topMenu.mm(1)}
                menu="about"
              >
                <div className="text">회사소개</div>
                {/*   <div className="icoArea mm_1">
                <img src="/assets/images/arrowDown.png" />
              </div> */}
              </CustomLink>
              {/*
            <ul className="mm mm_D1" style={{ display: "none" }}>
              <li>
                <Link to="/about">인사말</Link>
              </li>
              {  <li>
                <Link to="/about">관광사업등록증명</Link>
              </li> 
              </ul>*/}
            </dd>

            <dd className="pm">
              <CustomLink
                to="/philippines/package"
                onClick={() => topMenu.mm(2)}
                menu="philippines"
              >
                <div className="text">필리핀</div>
                {/* <div className="icoArea mm_2">
                <img src="/assets/images/arrowDown.png" />
              </div> */}
              </CustomLink>
              {/* 
            <ul className="mm mm_D2" style={{ display: "none" }}>
              <li>
                <Link
                  to="/philippines/package"
                  onClick={() => topMenu.mm_D2(1)}
                >
                  필리핀
                  <span className="mm_D2_1">
                    <img src="/assets/images/arrowDown.png" />
                  </span>
                </Link>
                <ul className="mm_D2_D1" style={{ display: "none" }}>
                  <li>
                    <Link to="/philippines/package">골프 패키지</Link>
                  </li>
                  <li>
                    <Link to="/philippines/golf">골프장정보</Link>
                  </li>
                  <li>
                    <Link to="/philippines/hotel">호텔정보</Link>
                  </li>
                  <li>
                    <Link to="/philippines/enjoy">필리핀 즐기기</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/philippines/subic/enjoy" onClick={topMenu.mm_D2(2)}>
                  수빅
                  <span className="mm_D2_2">
                    <img src="/assets/images/arrowDown.png" />
                  </span>
                </Link>
                <ul className="mm_D2_D2" style={{ display: "none" }}>
                  <li>
                    <Link to="/philippines/subic/enjoy">수빅즐기기</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="" onClick={mm_D2(2)}>
                  마닐라
                  <span className="mm_D2_2">
                    <img src="/assets/images/arrowDown.png" />
                  </span>
                </Link>
                <ul className="mm_D2_D2" style={{ display: "none" }}>
                  <li>
                    <Link to="">마닐라패키지</Link>
                  </li>
                  <li>
                    <Link to="">골프장정보</Link>
                  </li>
                  <li>
                    <Link to="">호텔정보</Link>
                  </li>
                  <li>
                    <Link to="">마닐라즐기기</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="">수빅</Link>
              </li>
            </ul> */}
            </dd>

            <dd className="pm">
              <CustomLink
                to="/japan/package"
                onClick={() => topMenu.mm(3)}
                menu="japan"
              >
                <div className="text">일본</div>
                {/*  <div className="icoArea mm_3">
                <img src="/assets/images/arrowDown.png" />
              </div> */}
              </CustomLink>

              {/*    <ul className="mm mm_D3" style={{ display: "none" }}>
              <li>
                <Link
                  to="/japan/package"
                  onClick={() => topMenu.mm_D3(1)}
                >
                  일본
                  <span className="mm_D3_1">
                    <img src="/assets/images/arrowDown.png" />
                  </span>
                </Link>
                <ul className="mm_D3_D1" style={{ display: "none" }}>
                  <li>
                    <Link to="/japan/package">골프 패키지</Link>
                  </li>
                  <li>
                    <Link to="/japan/golf">골프장정보</Link>
                  </li>
                  <li>
                    <Link to="/japan/hotel">호텔정보</Link>
                  </li>
                  <li>
                    <Link to="/japan/enjoy">골프 즐기기</Link>
                  </li>
                </ul>
              </li>
            </ul> */}
            </dd>

            <dd className="pm">
              <CustomLink
                to="/thailand/package"
                onClick={() => topMenu.mm(4)}
                menu="thailand"
              >
                <div className="text">태국</div>
                {/*   <div className="icoArea mm_4">
                <img src="/assets/images/arrowDown.png" />
              </div> */}
              </CustomLink>

              {/*   <ul className="mm mm_D4" style={{ display: "none" }}>
              <li>
                <Link
                  to="/thailand/package"
                  onClick={() => topMenu.mm_D4(1)}
                >
                  태국
                  <span className="mm_D4_1">
                    <img src="/assets/images/arrowDown.png" />`
                  </span>
                </Link>
                <ul className="mm_D4_D1" style={{ display: "none" }}>
                  <li>
                    <Link to="/thailand/package">골프 패키지</Link>
                  </li>
                  <li>
                    <Link to="/thailand/golf">골프장정보</Link>
                  </li>
                  <li>
                    <Link to="/thailand/hotel">호텔정보</Link>
                  </li>
                </ul>
              </li>
            </ul> */}
            </dd>
            <dd className="pm">
              <CustomLink
                to="/uae/package"
                onClick={() => topMenu.mm(5)}
                menu="uae"
              >
                <div className="text">두바이</div>
              </CustomLink>
            </dd>

            <dd>
              <CustomLink to="/notice" menu="notice">
                공지사항
              </CustomLink>
            </dd>
            <dd>
              <CustomLink to="/booking" menu="booking">
                예약문의
              </CustomLink>
            </dd>
            <dd>
              <CustomLink to="/gallery" menu="gallery">
                골프갤러리
              </CustomLink>
            </dd>
          </dl>
        </div>
      </header>
    </>
  );
};

export default TopNavigation;
