import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import BusinessList from "pages/main/business/businessList";
import { Link } from "react-router-dom";

const BusinessPage = () => {
  const location = useLocation();

  return (
    <section id="sub">
      <div className="subQTline">
        <div className="titleA">
          <div className="nm">Golfgogo</div>
          <div className="tit">Quick Service</div>
        </div>

        <ul className="quickMenu">
          <li>
            <Link to="/notice">
              <div className="ico">
                <img src="../../assets/images/sub/quickIco01.png" />
              </div>
              <div className="text">공지사항</div>
            </Link>
          </li>

          <li>
            <Link to="/booking">
              <div className="ico">
                <img src="../../assets/images/sub/quickIco02.png" />
              </div>
              <div className="text">예약문의</div>
            </Link>
          </li>

          <li>
            <Link to="/gallery">
              <div className="ico">
                <img src="../../assets/images/sub/quickIco03.png" />
              </div>
              <div className="text">골프갤러리</div>
            </Link>
          </li>
        </ul>

        <div className="nav">
          <div className="ico">
            <Link to="/">
              <img src="../assets/images/sub/navIco.png" />
            </Link>
          </div>
          <div className="tx">Gallery</div>
        </div>
      </div>

      <div className="bbCont">
        <div className="subjA">
          <div className="ensubj">Gallery</div>
          <div className="subj">골프갤러리</div>
        </div>
        <div className="container">
          {location.pathname.includes("detail") ? (
            <Outlet />
          ) : location.pathname.includes("write") ? (
            <Outlet />
          ) : location.pathname.includes("edit") ? (
            <Outlet />
          ) : (
            <BusinessList />
          )}
        </div>
      </div>
    </section>
  );
};

export default BusinessPage;
