import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./auth";

// auth를 통과하지 못하면 라우터에서 돌려보내는 래퍼
const RequireAuth = ({ children }) => {
  const auth = useAuth();
  console.log("RequireAuth >> ", auth);
  if (!auth.user) return <Navigate to="/admin" />;
  return children;
};

export default RequireAuth;
