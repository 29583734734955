import React, { useState, useEffect } from "react";
import InputUnit from "@/components/InputUnit";
import { useForm } from "react-hook-form";
import { serviceURL, useMutationData } from "@/api/hooks";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/auth/auth";
import { useRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";

const LogIn = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const { login } = useAuth();
  const [enter, { loading, data, err }] = useMutationData(
    serviceURL.getAdminUser,
  );
  const onValid = (_data) => {
    setUser(_data.id);
    enter({
      user_id: _data.id,
      user_pw: _data.pw,
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSuccess = () => {
    login(user);
    navigate("/admin-info", {
      state: { message: `반갑습니다 ${user}님!` },
      replace: true,
    });
  };

  const tryLogin = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.login,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setUserInfo({
            aToken: res.auth_info.access_token,
            isLogin: true,
            user_id: res.user_id,
          });
          localStorage.setItem("rToken", res.auth_info.refresh_token);
          navigate("/");
        } else {
          setUserInfo({
            aToken: null,
            isLogin: false,
            user_id: null,
            user_name: null,
            user_type: null,
          });
          localStorage.removeItem("rToken");
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    if (userInfo.userInfo != null) {
      navigate("/");
    }
  }, []);

  return (
    <form className="hook_form log_in" onSubmit={handleSubmit(tryLogin)}>
      <InputUnit
        label="ID"
        holder="아이디를 입력해주세요"
        register={register("id", {
          required: "아이디는 필수 입력 값 입니다.",
          minLength: {
            value: 3,
            message: "최소 3자 이상 입력 해 주세요",
          },
          maxLength: {
            value: 22,
            message: "최대 22자 이하로 입력 해 주세요",
          },
          pattern: {
            value: /^[a-z0-9]+[a-z0-9]+$/i,
            message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
          },
        })}
        errors={errors}
      />
      <InputUnit
        label="PW"
        type="password"
        holder="패스워드를 입력해주세요."
        register={register("pw", {
          required: "패스워드는 필수 입력 값 입니다.",
        })}
        errors={errors}
      />
      <button className="admin-enter__submit_btn" type="submit">
        로그인
      </button>
      {data?.message && <span className="error-message">{data.message}</span>}
      {data?.success && onSuccess()}
    </form>
  );
};

export default LogIn;
