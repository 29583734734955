import axios from "axios";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";

const BASE_URL =
  process.env.NODE_ENV == "development" ? "http://localhost:8910/api" : `/api`;

export const serviceURL = {
  getBoards: `${BASE_URL}/board/board`,
  getDetail: (postId = 1) => `${BASE_URL}/board/${postId}/boardDetail`,
  getAdminUser: `${BASE_URL}/authtest/getAdminUser`,
  setWriteNotice: `${BASE_URL}/board/setWriteNotice`,
  updateNotice: `${BASE_URL}/board/updateNotice`,
  deleteNotice: `${BASE_URL}/board/deleteNotice`,
};

//const queryClient = useQueryClient();

const getPosts = async (payload) => {
  const { data } = await axios({
    url: serviceURL.getBoards,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: payload,
  });
  return data;
};

const getPostById = async (postId) => {
  const { data } = await axios({
    url: serviceURL.getDetail(postId),
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ id: postId + "" }),
  });
  return data;
};

//////////////////////////////////////////////////////////////////

const usePosts = (payload) => {
  return useQuery(
    ["posts", payload],
    () => getPosts({ currentNavPage: payload }),
    // {
    //   onSettled: () => queryClient.invalidateQueries(["posts", payload]),
    //   onSuccess: (newData) => {
    //     queryClient.setQueryData(["posts", payload], (prevData) => {
    //       return { ...prevData, newData };
    //     });
    //   },
    // },
    // { retry: false },
    // {
    //   enabled: !!payload,
    // },
  );
};

// enabeld 옵션, id가 존재할 때만 쿼리 요청.
const usePost = (postId) => {
  return useQuery(
    ["post", postId],
    () => getPostById(postId),
    //   { retry: false },{
    //   enabled: !!postId,
    // } ,
  );
};

const useMutationData = (url) => {
  const [state, setState] = useState({
    loading: false,
    data: undefined,
    err: undefined,
  });
  function mutation(data) {
    setState((prev) => ({ ...prev, loading: true }));

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      /* json이 없다면 에러를 보이지 않음 */
      .then((response) => response.json().catch(() => {}))
      .then((data) => setState((prev) => ({ ...prev, data })))
      .catch((err) => setState((prev) => ({ ...prev, err })))
      .finally(() => setState((prev) => ({ ...prev, loading: false })));
  }
  return [mutation, { ...state }];
};

export { usePosts, usePost, useMutationData };
