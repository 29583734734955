import React from "react";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";

const cls = (...classnames) => classnames.join(" ");

const CustomLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  const location = useLocation();
  const menuMap = [
    "about",
    "philippines",
    "japan",
    "thailand",
    "uae",
    "notice",
    "booking",
    "gallery",
  ];
  const pathName = location.pathname;
  const menuName = menuMap.filter((menu) => pathName.includes(menu))[0];
  const propsMenu = props.menu;
  //console.log("propsMenu : ", propsMenu, " | ", match);
  return (
    <>
      <Link
        to={to}
        {...props}
        className={cls(propsMenu == menuName || match ? "active" : "")}
      >
        {children}
      </Link>
      {/* {match && "(active)"} */}
    </>
  );
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export { cls, scrollToTop, CustomLink };
