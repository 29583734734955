import React from "react";
import { Link, Outlet, useLocation, useMatch } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getCountry } from "@/api/contents";
import { cityAtom, LIST_TYPE, packageListType } from "@/recoil/atoms";
// import EnjoyList from "./common/enjoy";
// import GolfList from "./common/golf";
// import HotelList from "./common/hotel";
// import PackageList from "./common/package";

const City = () => {
  const [selectedListType, setSelectedListType] =
    useRecoilState(packageListType);
  let pathName = useLocation().pathname;
  const preIndex = pathName.indexOf("/") + 1;
  let country = pathName.slice(preIndex);
  const postIndex = country.indexOf("/") + 1;
  if (postIndex) country = country.slice(0, postIndex - 1);
  const countryData = getCountry()[country];
  let countryTitle = countryData?.title;
  const countryName = country[0].toUpperCase() + country.substring(1);
  const cityName = "골프";
  // const packageData = countryData?.package;
  // const golfData = countryData?.golf;
  // const hotelData = countryData?.hotel;
  // const enjoyData = countryData?.enjoy;
  countryTitle = countryTitle == "두바이" ? "두바이" : countryTitle;
  const packageName = `${countryTitle} ${cityName} 여행`;
  const isPackageDetail = useMatch("/:country/package/:id");
  const isPackage = useMatch("/:country/package/");
  const isGolf = useMatch("/:country/golf");
  const isHotel = useMatch("/:country/hotel");
  const isEnjoy = useMatch("/:country/enjoy");
  const isPackageList = isPackage || isPackageDetail;

  const onListTypeClick = (e) => {
    const listType =
      String(e.currentTarget.dataset.listType).toUpperCase() == LIST_TYPE.thumb
        ? LIST_TYPE.thumb
        : LIST_TYPE.list;
    setSelectedListType(listType);
  };
  return (
    <div className="subCont">
      <div className="subTitleArea">
        <div className="tit">
          <h1>{packageName}</h1>
        </div>
      </div>
      <div className="container">
        <div className="lcOpLine">
          <ul className="lcD2_menu">
            <li className={isPackageList ? "on" : null}>
              <Link data-link={countryName} to={`package`}>
                {cityName} 패키지
              </Link>
            </li>

            <li className={isGolf ? "on" : null}>
              <Link data-link="golf" to={`golf`}>
                골프장정보
              </Link>
            </li>

            <li className={isHotel ? "on" : null}>
              <Link data-link="hotel" to={`hotel`}>
                호텔정보
              </Link>
            </li>

            <li className={isEnjoy ? "on" : null}>
              <Link data-link="enjoy" to={`enjoy`}>
                {countryTitle} 즐기기
              </Link>
            </li>
          </ul>
          {isPackage && !isPackageDetail ? (
            <div className="opA">
              <ul className="listType">
                <li
                  className={selectedListType == LIST_TYPE.thumb ? "on" : null}
                >
                  <a data-list-type="thumb" onClick={onListTypeClick}>
                    <img src="/assets/images/sub/alignIco01.png" />
                  </a>
                </li>
                <li
                  className={selectedListType == LIST_TYPE.list ? "on" : null}
                >
                  <a data-list-type="list" onClick={onListTypeClick}>
                    <img src="/assets/images/sub/alignIco02.png" />
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
        {/* 
        {isDetail ? (
          <Outlet />
        ) : isPackageList ? (
          <PackageList packageData={packageData} country={country} />
        ) : null}

        {isGolf ? <GolfList golfData={golfData} country={country} /> : null}
        {isHotel ? <HotelList hotelData={hotelData} country={country} /> : null}
        {isEnjoy ? <EnjoyList enjoyData={enjoyData} country={country} /> : null}
  */}
        <Outlet />
        <div className="dimenuArea">
          <ul className="diMenu">
            {isPackageList ? null : (
              <li>
                <Link data-link="enjoy" to={`package`}>
                  <div className="ico">
                    <img src="../../../assets/images/sub/lcIcon.png" />
                  </div>
                  <div className="text">{cityName} 패키지</div>
                </Link>
              </li>
            )}
            {isGolf ? null : (
              <li>
                <Link data-link="enjoy" to={`golf`}>
                  <div className="ico">
                    <img src="../../../assets/images/sub/golfIco.png" />
                  </div>
                  <div className="text">골프장 정보</div>
                </Link>
              </li>
            )}
            {isHotel ? null : (
              <li>
                <Link data-link="enjoy" to={`hotel`}>
                  <div className="ico">
                    <img src="../../../assets/images/sub/hotelIco.png" />
                  </div>
                  <div className="text">호텔 정보</div>
                </Link>
              </li>
            )}
            {isEnjoy ? null : (
              <li>
                <Link data-link="enjoy" to={`enjoy`}>
                  <div className="ico">
                    <img src="../../../assets/images/sub/packageIco.png" />
                  </div>
                  {<div className="text">{countryTitle} 즐기기</div>}
                </Link>
              </li>
            )}
          </ul>

          <Link to="/booking">
            <div className="bookingBtn">예약문의</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default City;
