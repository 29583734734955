import React from "react";
import { useMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { channelIDMap, kakaoMapSelector } from "@/recoil/atoms";

const KakaoFloatingBtn = () => {
  const isHome = useMatch("/");
  const isPhilippines = useMatch("/philippines/*");
  const isJapan = useMatch("/japan/*");
  const isThailand = useMatch("/thailand/*");
  const isUAE = useMatch("/uae/*");
  const getCountryFromMain = useRecoilValue(kakaoMapSelector);
  let kakaoChannelID;
  const getSubChannelID = () => {
    kakaoChannelID = isPhilippines
      ? channelIDMap.philippines
      : isJapan
      ? channelIDMap.japan
      : isThailand
      ? channelIDMap.thailand
      : isUAE
      ? channelIDMap.uae
      : "";
    return kakaoChannelID;
  };
  const getMainChannelID = () => {
    kakaoChannelID = channelIDMap[getCountryFromMain];
    return kakaoChannelID;
  };

  const getChannelID = () => {
    kakaoChannelID = isHome ? getMainChannelID() : getSubChannelID();
    return kakaoChannelID;
  };

  if (isHome || isPhilippines || isJapan || isThailand || isUAE) {
    return (
      <>
        <div className="kakaoChat">
          <a
            href={`https://pf.kakao.com/${getChannelID()}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/images/kakao_chat_3.png"
              alt="골프고고 카카오톡 문의하기"
            />
          </a>
        </div>
      </>
    );
  } else {
    return "";
  }
};

export default KakaoFloatingBtn;
