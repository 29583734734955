import React, { useEffect, useState } from "react";
import QuickMenu from "components/common/quickMenu";
import moment from "moment";
import {
  Link,
  Outlet,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { serviceURL, useMutationData, usePosts } from "@/api/hooks";
import { cls } from "@/libs/helper";
import "./notice.css";

function Notice() {
  const navigate = useNavigate();
  const { id: detailId } = useParams();
  const [writeMode, setWriteMode] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const { isLoading, data } = usePosts(pageNum);
  const [enter, { loading: writeWaiting, data: writeData, err: writeErr }] =
    useMutationData(serviceURL.setWriteNotice);
  const [
    deleteEnter,
    { loading: deleteWaiting, data: deleteData, err: deleteErr },
  ] = useMutationData(serviceURL.deleteNotice);
  const location = useLocation();
  const noticeList = data?.boardList;

  const onGoToPage = (pageNum) => {
    setPageNum((prev) => (prev = pageNum));
  };
  const writeModeHandler = () => {
    if (location?.state?.editMode != "on") return;
    setWriteMode((current) => (current = !current));
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const title = document.querySelector("#title-area").value;
    const txt = document.querySelector("#text-area").value;
    if (!title || !txt) return;
    enter({ title: title, text: txt, user_id: location?.state?.user_id });
  };
  if (writeData?.success && !writeWaiting) {
    window.location.reload();
  }
  const onEditArticle = (board_id) => {
    navigate(`/notice/${board_id}`, { state: { editMode: "on" } });
  };
  const onDeleteArticle = (board_id) => {
    deleteEnter({ board_id: board_id });
  };
  useEffect(() => {
    if (!deleteWaiting && deleteData?.success) {
      navigate("/notice", { replace: true });
    }
  }, [deleteWaiting, deleteData]);

  return (
    <section id="sub">
      <div className="subQTline">
        <div className="titleA">
          <div className="nm">Golfgogo</div>
          <div className="tit">Quick Service</div>
        </div>

        <QuickMenu />

        <div className="nav">
          <div className="ico">
            <Link to="/">
              <img src="../assets/images/sub/navIco.png" />
            </Link>
          </div>
          <div className="tx">NOTICE</div>
        </div>
      </div>

      <div className="bbCont">
        <div className="subjA">
          <div className="ensubj">Notice</div>
          <div className="subj">공지사항</div>
        </div>
        <div className="boardListArea">
          {detailId ? (
            <Outlet />
          ) : isLoading ? (
            <div>Loading..</div>
          ) : writeMode ? (
            <>
              <div className="total">
                <span></span>
                {location?.state?.editMode == "on" && (
                  <button className="write-btn" onClick={writeModeHandler}>
                    목록으로 돌아가기
                  </button>
                )}
              </div>
              <form>
                <ul className="boardList write-on">
                  <li className="head">
                    <div className="noticeTitle">공지사항 작성하기</div>
                  </li>
                  <li className="input--wrapper">
                    <span className="input--container">
                      <input
                        className="title_input"
                        type="text"
                        id="title-area"
                        placeholder="제목을 작성해주세요."
                      />
                      <textarea
                        name="textarea"
                        id="text-area"
                        cols="30"
                        rows="10"
                        className="text_input"
                        placeholder="본문을 작성해주세요."
                      />
                    </span>
                  </li>
                  <li className="btnArea--wrapper">
                    <ul className="btnArea">
                      {writeWaiting ? (
                        <span>등록 중 입니다...</span>
                      ) : (
                        <>
                          <li>
                            <button type="submit" onClick={onSubmitHandler}>
                              등록하기
                            </button>
                          </li>
                          <li>
                            <button type="button" onClick={writeModeHandler}>
                              취소하기
                            </button>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                </ul>
              </form>
            </>
          ) : (
            <>
              <div className="total">
                <span>총 {JSON.parse(data?.data).totalrows}건</span>
                {location?.state?.editMode == "on" && (
                  <button className="write-btn" onClick={writeModeHandler}>
                    작성하기
                  </button>
                )}
              </div>

              <ul className="boardList">
                <li className="head">
                  <div className="num">No.</div>
                  {/* <div className="subj">제목</div> */}
                  <div
                    className={cls(
                      "subj",
                      location?.state?.editMode == "on" && "onEdit",
                    )}
                  >
                    제목
                  </div>
                  <div className="writer">작성자</div>
                  <div className="date">작성일</div>
                </li>
                {noticeList?.map((item, index) => (
                  <li key={index}>
                    <Link to={`/notice/${item.board_id}`}>
                      <div className="num">{item.board_id}</div>
                      <div className="subj">
                        <div className="subject">{item.title}</div>
                        {item.isNew ? (
                          <div className="new">
                            <img src="../../assets/images/sub/newIco.png" />
                          </div>
                        ) : null}
                      </div>
                      <div className="writer">{item.customer_id}</div>
                      <div className="date">
                        {moment(item.date).format("YYYY/MM/DD")}
                      </div>
                    </Link>
                    {location?.state?.user_id == item.customer_id && (
                      <span className="modify--container">
                        <button
                          className="edit_btn"
                          onClick={() => onEditArticle(item.board_id)}
                        >
                          수정
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() => onDeleteArticle(item.board_id)}
                        >
                          삭제
                        </button>
                      </span>
                    )}
                  </li>
                ))}
              </ul>

              <ul className="paging">
                <li>
                  {/* <Link to="/"> */}
                  <a
                    onClick={() =>
                      onGoToPage(pageNum - 10 < 1 ? 1 : pageNum - 10)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img src="../../assets/images/sub/pagingPrev.png" />
                  </a>
                  {/* </Link> */}
                </li>
                {data?.pageList.map((item, index) => (
                  <li key={index} className={item == pageNum ? "on" : null}>
                    {/* <Link to={`/notice/${item}`}>{item}</Link> */}
                    <a
                      onClick={() => onGoToPage(item)}
                      style={{ cursor: "pointer" }}
                    >
                      {item}
                    </a>
                  </li>
                ))}

                <li>
                  {/* <Link to="/"> */}
                  <a
                    onClick={() =>
                      onGoToPage(
                        data?.pageList[data?.pageList.length - 1] + 1 <
                          +JSON.parse(data?.data).maxPage
                          ? data?.pageList[data?.pageList.length - 1] + 1
                          : data?.pageList[data?.pageList.length - 1],
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img src="../../assets/images/sub/pagingNext.png" />
                  </a>
                  {/* </Link> */}
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Notice;
