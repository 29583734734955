import React from "react";
import Slick from "react-slick";
import Parser from "html-react-parser";
import { subJs } from "../../../assets/js/common";
import { useMatch } from "react-router-dom";
import { getCountry } from "../../../api/contents";
import CommonHelmet from "@/components/common/CommonHelmet";

const EnjoyList = () => {
  const isPackage = useMatch("/:country/enjoy");
  const country = isPackage?.params?.country || "philippines";
  const rawData = getCountry()[country];
  const enjoyData = rawData?.enjoy;
  const countryName = rawData?.title;
  const countryPath = country ? `${country}/enjoy` : "enjoy";
  const imgPrefix = `../../../assets/images/${countryPath}/`;
  return (
    <>
      <CommonHelmet text={`${countryName} 즐기기 :: 골프고고`} />
      <dl className="enjoyList">
        {enjoyData.map((item, index) => (
          <dd key={index}>
            <ul className={`enjoyVisual enjoyVisual_${index + 1}`}>
              <Slick
                /* asNavFor={`.enjoyVisualNav_${index + 1}`} */
                {...subJs.settingEnjoyVisual}
              >
                {item.images.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <img src={`${imgPrefix}${subItem}`} />
                  </li>
                ))}
              </Slick>
            </ul>
            {/*     <ul className={`enjoyVisualNav enjoyVisualNav_${index + 1}`}>
              <Slick          
                //asNavFor={`.enjoyVisual_${index + 1}`}  
                ref = {slider => setSlider2(slider)}
                asNavFor={slider1}
                {...settingEnjoyVisualNav}
              >
                {item.images.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <img src={`${imgPrefix}${subItem}`} />
                  </li>
                ))}
              </Slick>
            </ul> */}
            <div className="ta">
              <div className="subject">{Parser(item.title)}</div>
              <div className="subSubj">{Parser(item.subTitle)}</div>
              <div className="subs">{Parser(item.summary)}</div>
            </div>
          </dd>
        ))}
      </dl>
    </>
  );
};
export default EnjoyList;
