import React from "react";
import { Link } from "react-router-dom";
import Slick from "react-slick";
import QuickMenu from "components/common/quickMenu";
import { subJs } from "../assets/js/common";

function About() {
  return (
    <section id="sub">
      <div className="subQTline">
        <div className="titleA">
          <div className="nm">Golfgogo</div>
          <div className="tit">Quick Service</div>
        </div>

        <QuickMenu />

        <div className="nav">
          <div className="ico">
            <Link to="/">
              <img src="../assets/images/sub/navIco.png" />
            </Link>
          </div>
          <div className="tx">About Us</div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="introduce">
          <div className="cont">
            <div className="inner">
              <div className="enSubj">About Us</div>
              <div className="nm">
                편안하고 행복한 골프여행 <span>골프고고</span>
              </div>
              <div className="subj">
                안녕하세요!
                <br />
                골프고고를 방문해주셔서
                <br />
                감사드립니다.
              </div>

              <div className="subs">
                저희 골프고고는 필리핀 클락, 마닐라, 미야자키 등 골프 전문
                여행사로서 고객들의 Needs를 맞춘 투어 서비스 목적으로 최적화된
                상품을 제공하고 있습니다.
                <br />
                <br />
                또한 필리핀 현지 법인 등록이 공식으로 되어있으며, 현지 사무실
                운영으로 고객여러분의 편안하고 신뢰있는 서비스를 제공할 수
                있습니다. 요즘같은 불경기에 품질 높은 서비스에 저렴한 가격으로
                여러분을 맞이할 것을 약속드리며, 여행의 즐거운 추억을 만들어
                드리도록 노력하겠습니다.
              </div>
            </div>
          </div>
          <div className="bg">
            <img src="../assets/images/sub/introThum.png" />
          </div>
        </div>

        <div className="product">
          <div className="subSubj">상품소개</div>
          <div className="subj">골프고고 ‘힐링’ 명문골프</div>

          <ul className="productList">
            <li>
              <Link to="/philippines/package">
                <div className="tc">
                  <div className="enNm">Philippines</div>
                  <div className="nm">필리핀</div>
                  <div className="prnm">3박5일 + 매일 18홀 선택 C.C</div>
                </div>
                <div className="bg">
                  <img src="../assets/images/sub/productThum01.jpg" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/japan/package">
                <div className="tc">
                  <div className="enNm">Japan</div>
                  <div className="nm">일본</div>
                  <div className="prnm">3색 골프패키지</div>
                </div>
                <div className="bg">
                  <img src="../assets/images/sub/productThum02.jpg" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/thailand/package">
                <div className="tc">
                  <div className="enNm">Thailand</div>
                  <div className="nm">태국</div>
                  <div className="prnm">만능여행지 태국에서 즐기는 골프</div>
                </div>
                <div className="bg">
                  <img src="../assets/images/sub/productThum03.jpg" />
                </div>
              </Link>
            </li>
          </ul>
        </div>

        <div className="doc">
          <div className="inner">
            <div className="tit">관광사업등록증명서</div>
            <Slick {...subJs.settingDocList} className="docList">
              <li>
                <div className="thum">
                  <img src="../assets/images/sub/docThum01.jpg" />
                </div>
                <div className="subj">관광사업등록증명서</div>
              </li>

              <li>
                <div className="thum">
                  <img src="../assets/images/sub/docThum02.jpg" />
                </div>
                <div className="subj">필리핀 현지법인 관광사업자등록증명</div>
              </li>

              <li>
                <div className="thum">
                  <img src="../assets/images/sub/docThum03.jpg" />
                </div>
                <div className="subj">보증보험증권</div>
              </li>
              <li>
                <div className="thum">
                  <img src="../assets/images/sub/docThum04.jpg" />
                </div>
                <div className="subj">통장 사본</div>
              </li>
            </Slick>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
