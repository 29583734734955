import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CommonHelmet = ({ text }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{text}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default CommonHelmet;
