import React from "react";
import { useMatch } from "react-router-dom";
import { getCountry } from "@/api/contents";
import CommonHelmet from "@/components/common/CommonHelmet";

const HotelList = () => {
  const isPackage = useMatch("/:country/hotel");
  const country = isPackage?.params?.country || "philippines";
  const rawData = getCountry()[country];
  const hotelData = rawData?.hotel;
  const countryName = rawData?.title;
  const countryPath = country ? `${country}/hotel` : "hotel";
  const imgPrefix = `../../../assets/images/${countryPath}/`;
  return (
    <>
      <CommonHelmet text={`${countryName} 호텔 :: 골프고고`} />
      <dl className="hotelList">
        {hotelData.map((item, index) => (
          <dd key={index}>
            <div className="thumArea">
              {index % 2 == 0 && (
                <div className="bThum">
                  <img src={`${imgPrefix}${item.mainImage}`} />
                </div>
              )}
              <div className="sThumList">
                {item.images.map((subItem, subIndex) => (
                  <div key={subIndex}>
                    <img src={`${imgPrefix}${subItem}`} />
                  </div>
                ))}
              </div>
              {index % 2 != 0 && (
                <div className="bThum">
                  <img src={`${imgPrefix}${item.mainImage}`} />
                </div>
              )}
            </div>

            <div className="ta">
              <div className="nmLine">
                <div className="nm">{item.mainTitle}</div>
                <div className="en">{item.localeTitle}</div>
              </div>

              <div className="po">{item.subTitle}</div>
              <div className="subs">{item.summary}</div>
            </div>
          </dd>
        ))}
      </dl>
    </>
  );
};
export default HotelList;
