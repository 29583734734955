import IconClipBoardCheck from "@/components/common/svg/IconClipBoardCheck";
import IconClipBoard from "@/components/common/svg/IconClipBoard";
import React, { useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { channelIDMap } from "../recoil/atoms";
import { useEffect } from "react";
import { cls } from "@/libs/helper";
import QuickMenu from "components/common/quickMenu";

function Booking() {
  const [copied, setCopied] = useState({
    copy: false,
    uri: false,
    kakao: false,
  });
  const account_uri = useRef();
  const account_kakao = useRef();
  const message = {
    KAKAO: "카카오뱅크",
    URI: "우리은행",
  };
  const copyMotionStart = () => {
    setTimeout(() => {
      setCopied({ copy: false, uri: false, kakao: false });
    }, 3000);
  };
  const copy_to_clipboard = (ref) => {
    ref.current.select();
    document.execCommand("Copy");
    const isKakao = ref.current.classList.value.includes("ka");
    setCopied((prev) => ({ copy: true, uri: !isKakao, kakao: isKakao }));
    copyMotionStart();
  };
  useEffect(() => {
  }, []);
  return (
    <section id="sub">
      <div className="subQTline">
        <div className="titleA">
          <div className="nm">Golfgogo</div>
          <div className="tit">Quick Service</div>
        </div>

        <QuickMenu />

        <div className="nav">
          <div className="ico">
            <Link to="/">
              <img src="../assets/images/sub/navIco.png" />
            </Link>
          </div>
          <div className="tx">BOOKING</div>
        </div>
      </div>

      <div className="bbCont">
        <div className="subjA">
          <div className="ensubj">Reservation</div>
          <div className="subj">예약문의</div>
        </div>

        <div className="booking">
          <div className="inBox">
            <div className="tit">예약문의는 이메일과 전화로 문의해주세요</div>
            <ul className="inList">
              <li className="channel--wrapper">
                <div className="represent--container list-container">
                  <h2>대표채널</h2>
                  <dl className="represent-dl">
                    <dd className="represent-dd">
                      <a href="mailto:ohgod0429@naver.com">
                        <div className="ico">
                          <img src="../assets/images/sub/bookIco01.png" />
                        </div>
                        <div className="txA">
                          <div className="subj">Email</div>
                          <div className="ct">ohgod0429@naver.com</div>
                        </div>
                      </a>
                    </dd>
                    <dd className="represent-tel--container">
                      <a href="tel:070 7514 9171">
                        <div className="ico">
                          <img src="../assets/images/sub/bookIco02.png" />
                        </div>
                        <div className="txA">
                          <div className="subj">골프고고 연락처</div>
                          <div className="ct">070 7514 9171</div>
                        </div>
                      </a>
                    </dd>
                    <dd>
                      <a
                        href={`https://pf.kakao.com/${channelIDMap.home}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/kakao_consult_small_yellow_pc.png"
                          alt="카카오 톡상담"
                        />
                      </a>
                    </dd>
                  </dl>
                </div>
              </li>

              <li className="channel--wrapper">
                <div className="thai--container list-container">
                  <h2 className="thai-title">태국 담당</h2>
                  <dl className="thai-dl">
                    {/*  <dd className="thai-dd">
                        <a href="mailto:ohgod0429@naver.com">
                          <div className="ico">
                            <img src="../assets/images/sub/bookIco01.png" />
                          </div>
                          <div className="txA">
                            <div className="subj">Email</div>
                            <div className="ct">ohgod0429@naver.com</div>
                          </div>
                        </a>
                      </dd> */}
                    <dd></dd>
                    <dd>
                      <a
                        href={`https://pf.kakao.com/${channelIDMap.thailand}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/kakao_consult_small_yellow_pc.png"
                          alt="카카오 톡상담"
                        />
                      </a>
                    </dd>
                  </dl>
                </div>
              </li>

              <li className="channel--wrapper">
                <div className="dubai--container list-container">
                  <h2>두바이 담당</h2>
                  <dl className="dubai-dl">
                    <dd className="dubai-dd">
                      <a href="mailto:yckimdxb@gmail.com">
                        <div className="ico">
                          <img src="../assets/images/sub/bookIco01.png" />
                        </div>
                        <div className="txA">
                          <div className="subj">Email</div>
                          <div className="ct">yckimdxb@gmail.com</div>
                        </div>
                      </a>
                    </dd>
                    <dd className="dubai-tel--container">
                      <a href="tel:+971 50 395 2332">
                        <div className="ico">
                          <img src="../assets/images/sub/bookIco02.png" />
                        </div>
                        <div className="txA">
                          <div className="subj">두바이 담당자: 김영철</div>
                          <div className="ct">+971 50 395 2332</div>
                        </div>
                      </a>
                    </dd>
                    <dd>
                      <a
                        href={`https://pf.kakao.com/${channelIDMap.uae}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="../assets/images/kakao_consult_small_yellow_pc.png"
                          alt="카카오 톡상담"
                        />
                      </a>
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
            {/*  <dl>
                <dd>
                  <div>두바이 담당자 :</div>
                  <div>김영철</div>
                </dd>
                <dd>
                  <div>email :</div>
                  <div>yckimdxb@gmail.com</div>
                </dd>
                <dd>
                  <div>전화번호</div>
                  <div>+971 50 395 2332</div>
                </dd>
              </dl> */}
          </div>

          <div className="subjA account">
            <div className="ensubj">Deposit account</div>
            <div className="subj">입금계좌</div>
          </div>

          <div className="booking">
            <div className="inBox">
              {/* <div className="tit">입금계좌</div> */}
              <ul className="inList">
                <li className="channel--wrapper">
                  <div className="account--container list-container">
                    <h2
                      className={cls(
                        copied.copy ? "account-title on" : "account-title",
                      )}
                    >
                      {copied.copy ? (
                        <>
                          <IconClipBoardCheck />
                          <span>{`${
                            copied.uri ? message.URI : message.KAKAO
                          } 계좌번호가 복사되었어요!`}</span>
                        </>
                      ) : (
                        <IconClipBoard />
                      )}
                    </h2>
                    <dl className="account-dl space-around">
                      <dd
                        className="account-dd"
                        onClick={() => copy_to_clipboard(account_uri)}
                      >
                        <div className="txA">
                          <div className="ct">우리은행</div>
                          <div className="ct">1005-603-281034</div>
                          <div className="ct">주식회사골프고고</div>
                          <input
                            className="ct"
                            type="text"
                            readOnly
                            value="1005-603-281034"
                            ref={account_uri}
                          />
                        </div>
                      </dd>
                      <dd
                        className="account-dd"
                        onClick={() => copy_to_clipboard(account_kakao)}
                      >
                        <div className="txA">
                          <div className="ct">우리은행</div>
                          <div className="ct">1002-457-415002</div>
                          <div className="ct">임현주</div>
                          <input
                            className="ct ka"
                            type="text"
                            readOnly
                            value="3333-12-7763500"
                            ref={account_kakao}
                          />
                        </div>
                      </dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="cancleTbox">
            <div className="subjLine">
              <div className="ico">
                <img src="../assets/images/sub/alIco.png" />
              </div>
              <div className="subj">취소료 규정 안내</div>
            </div>

            <div className="subs">
              여행 취소시 국외 여행표준 약관 제 15조 소비자분쟁해결규정에 따라
              아래의 비율로 취소료가 부과됩니다.
              <br />
              (단, 당사의 귀책사유로 여행출발 취소 경우에도 동일한 규정이
              적용됩니다.)
            </div>

            <ul className="tList">
              <li>
                <div className="subj">여행개시 30일 전(~30)까지 통보시</div>
                <div className="subs">계약금 환급</div>
              </li>

              <li>
                <div className="subj">여행개시 20일 전(29~20)까지 통보시</div>
                <div className="subs">총 상품 가격의 10% 배상</div>
              </li>

              <li>
                <div className="subj">여행개시 10일 전(19~10)까지 통보시</div>
                <div className="subs">총 상품 가격의 15% 배상</div>
              </li>

              <li>
                <div className="subj">여행개시 8일 전(9~8)까지 통보시</div>
                <div className="subs">총 상품 가격의 20% 배상</div>
              </li>

              <li>
                <div className="subj">여행개시 1일 전(7~1)까지 통보시</div>
                <div className="subs">총 상품 가격의 30% 배상</div>
              </li>

              <li>
                <div className="subj">여행당일 통보시</div>
                <div className="subs">총 상품 가격의 50% 배상</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Booking;
