import React from "react";
import { Link, useMatch } from "react-router-dom";
import Parser from "html-react-parser";
import Slick from "react-slick";
import { getCountry } from "@/api/contents";
import { mainJs } from "@/assets/js/common";
import CommonHelmet from "@/components/common/CommonHelmet";

const PackageDetail = () => {
  const isPackage = useMatch("/:country/package/:id");
  const country = isPackage?.params?.country || "philippines";
  const id = isPackage?.params?.id || 0;
  const rawData = getCountry()[country];
  const packageData = rawData?.package;
  const countryName = rawData?.title;
  const selectedData = packageData.filter((item) => item.id == id)[0]
    .detailData[0];
  const countryPath = country ? `${country}/package` : "package";
  const imgPrefix = `../../../assets/images/${countryPath}/`;

  return (
    <>
      <CommonHelmet text={`${countryName} 골프 패키지 :: 골프고고`} />
      <div className="packageView manilaPackage">
        {selectedData.images ? (
          <div className="pvA">
            <ul className="pvVisual">
              <Slick {...mainJs.pvVisualSetting}>
                {selectedData.images.map((item, index) => (
                  <li key={index}>
                    <img src={`${imgPrefix}${item}`} />
                  </li>
                ))}
              </Slick>
            </ul>
          </div>
        ) : null}

        <div className="pvDeInfo">
          <div className="dsLine">
            <div className="subjA">
              <div className="subj">{Parser(selectedData.mainTitle)}</div>
              <div className="subs">{Parser(selectedData.subTitle)}</div>
            </div>

            <div className="sbA">
              <Link to="/booking">
                <div className="bookBtn">예약문의</div>
              </Link>
            </div>
          </div>

          <div className="price">
            {Number(selectedData.price).toLocaleString()}
            <span>{selectedData.unit}</span>
          </div>
          <div className="di">{Parser(selectedData.optionTitle1)}</div>
          <div className="arT">* {selectedData.optionTitle2}</div>
        </div>

        <dl className="dtInfoList">
          <dd>
            <div className="titA">상세정보</div>
            <div className="contA">
              <ul className="detailInfo">
                <li>
                  <div className="subj">포함사항</div>
                  <div className="subs">{Parser(selectedData.include)}</div>
                </li>
                {selectedData.exclude ? (
                  <li>
                    <div className="subj">불포함 사항</div>
                    <div className="subs">{Parser(selectedData.exclude)}</div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </dd>

          <dd>
            <div className="titA">일정안내</div>
            <div className="contA">
              <div className="scheduleBox">
                <div className="subjLine">
                  {selectedData.itineraryDate} 일정표
                </div>
                <div className="scheduleListBox">
                  <div className="line"></div>
                  <ul className="scheduleList">
                    {selectedData.itinerary.map((item, index) => (
                      <li key={index}>
                        <div className="day">{index + 1}D</div>
                        <div className="ct">{Parser(item)}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="alrText">
                {selectedData.warnText.map((item, index) => (
                  <span key={index}>* {Parser(item)}</span>
                ))}
              </div>
            </div>
          </dd>

          <dd>
            <div className="titA">취소료규정</div>
            <div className="contA">
              <ul className="detailInfo">
                <li>
                  <div className="subj">가.</div>
                  <div className="subs">
                    여행개시 30일전까지 통보시: 계약금환급
                  </div>
                </li>
                <li>
                  <div className="subj">나.</div>
                  <div className="subs">
                    여행개시 20일전까지 통보시: 여행요금의 10% 배상
                  </div>
                </li>
                <li>
                  <div className="subj">다.</div>
                  <div className="subs">
                    여행개시 10일전까지 통보시: 여행요금의 15% 배상
                  </div>
                </li>
                <li>
                  <div className="subj">라.</div>
                  <div className="subs">
                    여행개시 8일전까지 통보시: 여행요금의 20% 배상
                  </div>
                </li>
                <li>
                  <div className="subj">바.</div>
                  <div className="subs">
                    여행개시 당일 통보시: 여행요금의 50% 배상
                  </div>
                </li>
              </ul>
              <div className="alrText">
                <span key="a1">
                  * 여행 취소시 국외여행표준약관 제 16조 소비자분쟁해결규정에
                  따라
                  <br />
                  취소료규정의 비율로 취소료가 부과됩니다.
                  <br />
                </span>
                <span key="a2">
                  * 단, 당사의 귀책사유로 여행출발 취소 경우에도 동일한 규정이
                  적용됩니다.
                  <br />
                </span>
              </div>
            </div>
          </dd>
        </dl>
      </div>
    </>
  );
};
export default PackageDetail;
