import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="inner">
        <div className="topLine">
          <div className="footLogo">
            <img src="/assets/images/footLogo.png" alt="" />
          </div>
          <ul className="snsList">
            <li>
              <Link to="">
                <img src="/assets/images/snsIco01.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="">
                <img src="/assets/images/snsIco02.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="">
                <img src="/assets/images/snsIco03.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="">
                <img src="/assets/images/snsIco04.png" alt="" />
              </Link>
            </li>
          </ul>
        </div>

        <ul className="footMenu">
          <li>
            <Link to="/about">회사소개</Link>
          </li>
          <li>
            <Link to="/philippines">필리핀</Link>
          </li>
          <li>
            <Link to="/japan">일본</Link>
          </li>
          <li>
            <Link to="/thailand">태국</Link>
          </li>
          <li>
            <Link to="/uae">두바이</Link>
          </li>
          <li>
            <Link to="/notice">공지사항</Link>
          </li>
          <li>
            <Link to="/booking">예약문의</Link>
          </li>
          <li>
            <Link to="/gallery">골프갤러리</Link>
          </li>
        </ul>

        <div className="copy">
          한국법인 : (주)골프고고 / 필리핀 현지법인 : Clarkgogo
          <br />
          사업자번호 : 597-87-00787 / 관광사업자등록번호 : 제2017-69 / E-mail :
          ohgod0429@naver.com
          <br />
          주소 : 서울 강남구 테헤란로 423, 9층(삼성동, 현대타워) / 연락처 : 070
          7514 9171
          <br />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
