import React from "react";
import { cls } from "../libs/helper";

const InputUnit = ({
  label,
  type = "text",
  holder,
  register,
  errors,
  ...rest
}) => {
  const id = register.name;
  let placeholder;
  holder
    ? (placeholder = holder)
    : (placeholder = `${id.toString().toUpperCase()}를 입력해 주세요`);
  return (
    <div className="input-unit">
      {/* <label className="input-hook__label" htmlFor={id}>
        - {label}
      </label> */}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        className={cls("input-hook", errors[id] && "error")}
        {...register}
        {...rest}
      />
      {errors[id] && (
        <span className="error-message">{errors[id].message}</span>
      )}
    </div>
  );
};

export default InputUnit;
