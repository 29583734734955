import React, { useRef, useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize";
import {
  formats,
  toolbarOptions,
  imageHandler,
} from "components/common/quill/quillOptions";
import "react-quill/dist/quill.snow.css";
Quill.register("modules/ImageResize", ImageResize);

const BusinessWrite = () => {
  const [quillValue, setQuillValue] = useState("");
  const { apiUrls, axiosFetch, axiosFetchForm } = useAxiosFunction();
  const navigate = useNavigate();
  const quillRef = useRef();
  const cancelWrite = () => {
    navigate("/gallery");
  };
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const writeBusiness = (reqData) => {
    if (quillValue === undefined || quillValue === "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    reqData.content = quillValue.replace(/"/g, '\\"');

    axiosFetch({
      method: "post",
      url: apiUrls.writeBusiness,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          cancelWrite();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement("input");
    // 속성 써주기
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener("change", async () => {
      const file = input.files[0];

      if (file.size > 4 * 1024 * 1024) {
        alert("이미지 파일 크기는 4MB까지만 가능합니다.");
        return;
      } else if (!file.type.match("image/.*")) {
        alert("이미지 파일만 업로드 가능합니다.");
        return;
      }

      // multer에 맞는 형식으로 데이터 만들어준다.
      const formData = new FormData();
      formData.append("img", file); // formData는 키-밸류 구조

      // 백엔드 multer라우터에 이미지를 보낸다.
      axiosFetchForm({
        method: "post",
        url: apiUrls.saveWriteImg,
        formData: formData,
      })
        .then((res) => {
          console.log("res.url > ", res.url);
          const IMG_URL = res.url;
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection();
          editor.insertEmbed(range.index, "image", IMG_URL);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: imageHandler,
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    };
  }, []);

  useEffect(() => {}, []);

  return (
    <section className="inner business-gallery">
      <form className="input-area" onSubmit={handleSubmit(writeBusiness)}>
        <div className="input-style">
          <InputUnit
            type="text"
            holder="제목을 입력해주세요"
            register={register("title")}
            errors={formErrors}
          />
        </div>

        <ReactQuill
          className="writeContent"
          placeholder="내용을 입력해주세요."
          {...register("content")}
          theme="snow"
          value={quillValue}
          onChange={setQuillValue}
          modules={modules}
          formats={formats}
          ref={quillRef}
        />

        <div className="btn-area">
          <button
            type="submit"
            className="button-primary size-s"
            style={{ background: "#623189" }}
            disabled={isSubmitting}
          >
            <span>등록</span>
          </button>
          <div className="divide" />
          <button
            type="button"
            className="button-primary size-s"
            style={{ background: "#623189" }}
            onClick={cancelWrite}
          >
            취소
          </button>
        </div>
      </form>
    </section>
  );
};

export default BusinessWrite;
