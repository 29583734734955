import React, { useEffect, useState } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { getCountry } from "@/api/contents";
import City from "./city";
import QuickMenu from "components/common/quickMenu";

const Country = () => {
  const countryMatch = useMatch("/:country");
  const naviagate = useNavigate();
  const pathName = useLocation().pathname;
  const preIndex = pathName.indexOf("/") + 1;
  let country = pathName.slice(preIndex);
  const postIndex = country.indexOf("/") + 1;
  if (postIndex) country = country.slice(0, postIndex - 1);

  country = countryMatch?.params?.country || country;
  let countryName = country[0].toUpperCase() + country.substring(1);
  countryName = countryName == "Uae" ? "United Arab Emirates" : countryName;
  const [countryData, setCountryData] = useState();
  useEffect(() => {
    setCountryData(getCountry()[country]);
    countryMatch && naviagate("package");
  }, [country]);

  return (
    <section id="sub">
      <div className="subQTline">
        <div className="titleA">
          <div className="nm">Golfgogo</div>
          <div className="tit">Quick Service</div>
        </div>

        <QuickMenu />

        <div className="nav">
          <div className="ico">
            <Link to="/">
              <img src="/assets/images/sub/navIco.png" />
            </Link>
          </div>
          <div className="tx">{countryData?.name.toUpperCase()}</div>
          {/* <div className="tx">{countryData?.cities[cityIndex]?.title}</div> */}
        </div>
      </div>

      <div className="subTitleImg">
        <div className="textBox">
          <div className="lcNm">{countryName}</div>
          <div className="tit">{countryData?.subTitle1}</div>
          <div className="subT">{countryData?.subTitle2}</div>
        </div>

        <div className="bg">
          <img src="/assets/images/sub/philippineBg.jpg" />
        </div>
      </div>

      <City />
    </section>
  );
};

export default Country;
