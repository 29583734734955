import React from "react";
import { useMatch } from "react-router-dom";
import Slick from "react-slick";
import { getCountry } from "@/api/contents";
import { subJs } from "@/assets/js/common";
import CommonHelmet from "@/components/common/CommonHelmet";

const GolfList = () => {
  const isPackage = useMatch("/:country/golf");
  const country = isPackage?.params?.country || "philippines";
  const rawData = getCountry()[country];
  const golfData = rawData?.golf;
  const countryName = rawData?.title;
  const countryPath = country ? `${country}/golf` : "golf";
  const imgPrefix = `../../../assets/images/${countryPath}/` || "";
  const uaeHeader = () => {
    return (
      <div className="uaeHeader">
        <p className="txt1">
          ※ 중동 특성상 일반 캐디는 없고 GPS 장착된 카트(2인 1조)를 직접
          운전하시게 됩니다.
        </p>
        <p className="txt2">
          카트는 페어웨이 진입 가능합니다.(보수 공사 기간 제외)
        </p>
      </div>
    );
  };
  return (
    <>
      <CommonHelmet text={`${countryName} 골프장 :: 골프고고`} />
      {country == "uae" ? uaeHeader() : null}
      <dl className="golfList">
        {golfData.map((item, index) => (
          <dd key={index}>
            <div className="visualA">
              <div className="num">{index.toString().padStart(2, "0")}</div>
              <Slick {...subJs.settingGolfLoVisual} className={"golfLoVisual"}>
                {item.images.map((image, subIndex) => (
                  <div key={subIndex}>
                    <img src={`${imgPrefix}${image}`} />
                  </div>
                ))}
              </Slick>
            </div>

            <div className="ta">
              <div className="nm">{item.name}</div>
              <ul className="inList">
                {item.location.length == 0 ? null : (
                  <li>
                    <div className="subjArea">
                      <div className="ico">
                        <img src="../../../assets/images/sub/golfListIco01.png" />
                      </div>
                      <div className="subj">위치</div>
                    </div>
                    <div className="subs">{item.location}</div>
                  </li>
                )}
                {item.holeNumbers.length == 0 ? null : (
                  <li>
                    <div className="subjArea">
                      <div className="ico">
                        <img src="../../../assets/images/sub/golfListIco02.png" />
                      </div>
                      <div className="subj">홀수</div>
                    </div>
                    <div className="subs">{item.holeNumbers}</div>
                  </li>
                )}
                {item.facility.length == 0 ? null : (
                  <li>
                    <div className="subjArea">
                      <div className="ico">
                        <img src="../../../assets/images/sub/golfListIco03.png" />
                      </div>
                      <div className="subj">부대시설</div>
                    </div>
                    <div className="subs">{item.facility}</div>
                  </li>
                )}

                <li>
                  <div className="subjArea">
                    <div className="ico">
                      <img src="../../../assets/images/sub/golfListIco04.png" />
                    </div>
                    <div className="subj">주요사항</div>
                  </div>
                  <div className="subs">{item.summary}</div>
                </li>
              </ul>
            </div>
          </dd>
        ))}
      </dl>
    </>
  );
};

export default GolfList;
