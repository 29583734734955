import React, { useCallback, useEffect, useState } from "react";
import Parser from "html-react-parser";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { serviceURL, useMutationData, usePost } from "../api/hooks";
import { cls } from "../libs/helper";
import { MenuIcon, SaveIcon } from "../assets/images/icons/icons";

const NoticeDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading, data } = usePost(id);
  let currentData = data?.data[0];
  const onHistoryBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const location = useLocation();
  const [title, setEditTitle] = useState("");
  const [content, setEditContent] = useState("");

  const doSettingData = (currentData) => {
    setEditTitle((current) => (current = currentData?.title));
    setEditContent((current) => (current = currentData?.content));
  };
  useEffect(() => {
    doSettingData(currentData);
    return doSettingData;
  }, [currentData]);

  const editMode = location?.state?.editMode == "on" ? true : false;
  const onTitleChange = useCallback((e) => {
    console.log(e.target.value);
    const { value } = e.target;
    setEditTitle((current) => (current = value));
  }, []);
  const onContentChange = useCallback((e) => {
    console.log(e.target.value);
    const { value } = e.target;
    setEditContent((current) => (current = value));
  }, []);
  const [enter, { loading: updateLoading, data: updateData, err }] =
    useMutationData(serviceURL.updateNotice);
  const onContentSave = () => {
    enter({
      board_id: id,
      user_id: currentData?.customer_id,
      title: title,
      text: content,
    });
    if (data.status_code == "200")
      navigate("/notice", { state: { reload: true } });
  };

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className={cls("boardViewArea", editMode ? "editMode" : "")}>
      <form>
        <div className="diArea">
          <div className="subjA">
            {currentData?.badge == "공지" ? (
              <div className="noticeBat">공지</div>
            ) : null}
            <div className="subj">
              {editMode ? (
                <input
                  type="text"
                  className="title_input"
                  placeholder="제목을 작성해 주세요."
                  value={!isLoading && title}
                  onChange={onTitleChange}
                />
              ) : (
                currentData?.title
              )}
            </div>
          </div>

          <ul className="iList">
            <li>작성자 : {currentData?.customer_id}</li>
            <li>
              {moment(currentData?.board_date).format("YYYY/MM/DD - HH:mm")}
            </li>
          </ul>
        </div>

        <div className="viewBox">
          <div className="viewCont">
            {editMode ? (
              <textarea
                className="content_input"
                /* value={Parser(content)} */
                placeholder="내용을 작성해 주세요."
                value={content}
                onChange={onContentChange}
              />
            ) : (
              <span>{Parser(currentData?.content)}</span>
            )}
          </div>
          <ul className="btnArea">
            {editMode && (
              <li>
                <a onClick={onContentSave}>
                  <div className="ico">
                    <SaveIcon />
                  </div>
                  <div className="tx">저장</div>
                </a>
              </li>
            )}
            <li>
              <a onClick={onHistoryBack}>
                <div className="ico">
                  <MenuIcon />
                </div>
                <div className="tx">목록</div>
              </a>
            </li>
          </ul>
        </div>
      </form>
      <div className="boardListArea"></div>
    </div>
  );
};

export default NoticeDetail;
