import { useState, useEffect } from "react";
import { apiUrls } from "../data/apiUrl";
import axios from "axios";

const useAxios = () => {
  const axiosInstance = axios.create();
  const baseURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8910/api"
      : `/api`;

  axiosInstance.interceptors.request.use(
    (config) => {
      config.baseURL = baseURL;
      config.headers["Content-Type"] = "application/json; charset=utf-8";
      config.headers["Accpet"] = "*/*";

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

const useAxiosForm = () => {
  const axiosInstance = axios.create();
  const baseURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8910/api"
      : `/api`;

  axiosInstance.interceptors.request.use(
    (config) => {
      config.baseURL = baseURL;
      config.headers["Content-Type"] = "multipart/form-data";
      config.headers["Accpet"] = "*/*";

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  return axiosInstance;
};

const useAxiosFunction = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); //different!
  const [controller, setController] = useState();
  const axiosInstance = useAxios();
  const axiosFormInstance = useAxiosForm();
  const urls = apiUrls;

  const axiosFetch = async (configObj) => {
    const { method, url, requestConfig = {} } = configObj;
    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosInstance[method.toLowerCase()](url, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      setResponse(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log("err > ", err);

      setError(err.message);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const axiosFetchDown = async (configObj) => {
    const { method, url, requestConfig } = configObj;

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosInstance[method.toLowerCase()](url, {
        ...requestConfig,
        signal: ctrl.signal,
        responseType: "blob",
      });
      setResponse(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      setError(err.message);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const axiosFetchForm = async (configObj) => {
    const { method, url, formData, requestConfig = {} } = configObj;

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosFormInstance[method.toLowerCase()](url, formData, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      setResponse(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      setError(err.message);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return {
    response,
    error,
    loading,
    urls,
    axiosFetch,
    apiUrls,
    axiosFetchForm,
    axiosFetchDown,
  };
};

export default useAxiosFunction;
